<mat-accordion
  class="myt-expansion-panel myt-expansion-panel--{{ panelType() }}"
  [togglePosition]="togglePosition()"
  [ngClass]="{
    'myt-expansion-panel--highlight': isHighlighted(),
    'myt-expansion-panel--large': large(),
    'myt-expansion-panel--divider': divider(),
    'myt-expansion-panel--no-hover': noHover(),
    'myt-expansion-panel--no-content-padding': noContentPadding(),
  }"
>
  <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <ng-content select="[expansionPanelTitle]" />
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-content select="[expansionPanelContent]" />
  </mat-expansion-panel>
</mat-accordion>
